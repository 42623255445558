.reportCalendar {
  font-family:'Barlow-Regular', sans-serif;
  font-size: 16px;
  box-shadow: none;
  margin-bottom: 100px !important ;
  }
  .reportCalendar.ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date-value {
    text-align: start;
  }
  .reportCalendar.ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date-content {
    height: 170px;
  }

  .reportCalendar .ant-picker-panel .ant-picker-date-panel .ant-picker-body .ant-picker-content thead  {
    font-size: 30px;
    text-align: center;
   }
  .reportCalendar .ant-picker-panel .ant-picker-date-panel .ant-picker-body .ant-picker-content thead tr th {
  padding:  20px 0px;
   }
   .reportCalendar .ant-picker-panel .ant-picker-date-panel .ant-picker-body .ant-picker-content tbody td {
    border: 1px solid #CCC;
  }
  .reportCalendar.ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date {
    border-top: none;
  }

  .reportCalendar.ant-picker-calendar-full .ant-picker-panel .ant-picker-body th, .ant-picker-calendar-full .ant-picker-panel .ant-picker-body td {
    border: 1px solid #CCC;
  }
  