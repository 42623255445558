.red-circle::after {
  content: '';
  position: absolute;
  width: 100%;
  padding-bottom: 90%;
  border-radius: 100%;
  border: 2px solid red;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}

.red-circle {
  position: relative !important;
}
