.dateRangePicker {
z-index: 100000 !important;
font-family:'Barlow-Regular', sans-serif;
font-size: 16px;
box-shadow: none;
}

.dateRangePicker
  .ant-picker-cell-in-view.ant-picker-cell-range-start
  .ant-picker-cell-inner {
  background-color: #91C03E !important;
}

.dateRangePicker
  .ant-picker-cell-in-view.ant-picker-cell-range-end
  .ant-picker-cell-inner {
  background-color: #91C03E !important;
}

.dateRangePicker
.ant-picker::focused{
    border-color: none;
    box-shadow: none;
    border-right-width: 0px;
}

.dateRangePicker
  .ant-picker-cell-in-view.ant-picker-cell-today
  .ant-picker-cell-inner::before {
  border-color: #F2A122 !important;
}

.dateRangePicker
  .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single)::before,
.dateRangePicker
  .ant-picker-cell-in-view.ant-picker-cell-in-range::before,
.dateRangePicker
  .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single)::before,
.dateRangePicker
  .ant-picker-time-panel-column
  > li.ant-picker-time-panel-cell-selected
  .ant-picker-time-panel-cell-inner {
  background: #eaf3d8 !important;
}

.dateRangePicker .ant-picker-header-super-next-btn {
  display: none;
}
.dateRangePicker .ant-picker-header-super-prev-btn {
  display: none;
}

.dateRangePicker .ant-picker-month-btn {
  font-weight: 600;
}
.dateRangePicker .ant-picker-year-btn {
  font-weight: 600;
}
.dateRangePicker .ant-picker-range-arrow {
  display: none;
}

.dateRangePicker .ant-picker-panel-container .ant-picker-panel .ant-picker-date-panel .ant-picker-body .ant-picker-content thead tr th:nth-child(1) {
  display: none;
}

.dateRangePicker .ant-picker-panel-container .ant-picker-panel .ant-picker-date-panel .ant-picker-body .ant-picker-content thead tr th:nth-child(2) {
 display: none;
}

.dateRangePicker .ant-picker-panel-container .ant-picker-panel .ant-picker-date-panel .ant-picker-body .ant-picker-content thead tr th:nth-child(3) {
 display: none;
}

.dateRangePicker .ant-picker-panel-container .ant-picker-panel .ant-picker-date-panel .ant-picker-body .ant-picker-content thead tr th:nth-child(4) {
 display: none;
}

.dateRangePicker .ant-picker-panel-container .ant-picker-panel .ant-picker-date-panel .ant-picker-body .ant-picker-content thead tr th:nth-child(5) {
 display: none;
}

.dateRangePicker .ant-picker-panel-container .ant-picker-panel .ant-picker-date-panel .ant-picker-body .ant-picker-content thead tr th:nth-child(6) {
 display: none;
}

.dateRangePicker .ant-picker-panel-container .ant-picker-panel .ant-picker-date-panel .ant-picker-body .ant-picker-content thead tr th:nth-child(7) {
 display: none;
}

