

/* * {
    font-family: sans-serif;
    font-size: 17px;
  } */
/*   
  body {
    background: #f2f2f2;
    padding: 20px;
  } */
  
  .input-tag {
    margin-top: 20px;
    background: #fff;
    border: 1px solid #CACFD0;
    border-radius: 6px;
    display: flex;
    flex-wrap: wrap;
    padding: 5px 5px 0;
    font-family: 'var(--regular)';
    font-size: 17px;
  }
  
  .input-tag input {
    border: none;
    width: 100%;
  }
  
  .input-tag__tags {
    display: inline-flex;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
    width: 100%;
  }
  
  .input-tag__tags li {
    align-items: center;
    background: #85A3BF;
    border-radius: 2px;
    color: white;
    display: flex;
    font-weight: 300;
    list-style: none;
    margin-bottom: 5px;
    margin-right: 5px;
    padding: 5px 10px;
  }
  
  .input-tag__tags li button {
    align-items: center;
    appearance: none;
    background: #333333;
    border: none;
    border-radius: 50%;
    color: white;
    cursor: pointer;
    display: inline-flex;
    font-size: 12px;
    height: 15px;
    justify-content: center;
    line-height: 0;
    margin-left: 8px;
    padding: 0;
    transform: rotate(45deg);
    width: 15px;
  }
  
  .input-tag__tags li.input-tag__tags__input {
    background: none;
    flex-grow: 2;
    padding: 0;
  }
  .input-tag__tags li.input-tag__tags__input input{
    border: none;
    outline: none;
    color: black;
  }
  