.public-DraftEditor-content {
  min-height: 40px;
  padding: 5px;
}

.DraftEditor-editorContainer,
.DraftEditor-root,
.public-DraftEditor-content {
  height: auto !important;
}

.public-DraftStyleDefault-ltr {
  min-height: 35px !important;
}

.public-DraftEditorPlaceholder-root {
  color: #455154;
  font-size: 16px;
  font-family: 'Barlow-Regular', sans-serif;
  opacity: 0.6;
}
