.select-scrollbar-visible .rc-virtual-list-scrollbar {
  display: block !important;
}

.date_range_click {
  padding-left: 8px;
  cursor: pointer;
}

.disabled-option {
  display: none;
}
