@import "./fonts.css";


* {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  --sf-d-reg: 'SF-Pro-Display-Regular', sans-serif;
  --sf-d-light: 'SF-Pro-Display-Light', sans-serif;
  --sf-d-bold: 'SF-Pro-Display-Bold', sans-serif;
  --sf-d-med: 'SF-Pro-Display-Medium', sans-serif;
  --sf-d-semiBold: 'SF-Pro-Display-Semibold', sans-serif;
  --sf-t-reg: 'SF-Pro-Text-Regular', sans-serif;
  --sf-t-light: 'SF-Pro-Text-Light', sans-serif;
  --sf-t-bold: 'SF-Pro-Text-Bold', sans-serif;
  --sf-t-med: 'SF-Pro-Text-Medium', sans-serif;
  --sf-t-semiBold: 'SF-Pro-Text-Semibold', sans-serif;
  --open-sans-reg: 'Open-Sans-Regular', sans-serif;
  --open-sans-bold: 'Open-Sans-Bold', sans-serif;
  --open-sans-semiBold: 'Open-Sans-SemiBold', sans-serif;
  --open-sans-light: 'Open-Sans-Light', sans-serif;
  --lato-reg: 'Lato-Regular', sans-serif;
  --lato-bold: 'Lato-Bold', sans-serif;
  --lato-med: 'Lato-Medium', sans-serif;
  --lato-semiBold: 'Lato-SemiBold', sans-serif;
  --lato-light: 'Lato-Light', sans-serif;
  --thin: 'Barlow-Thin', sans-serif;
  --light: 'Barlow-Light', sans-serif;
  --regular: 'Barlow-Regular', sans-serif;
  --medium: 'Barlow-Medium', sans-serif;
  --semiBold: 'Barlow-SemiBold', sans-serif;
  --bold: 'Barlow-Bold', sans-serif;
  --inter-bold: 'Inter-Bold', sans-serif;
  --inter-regular: 'Inter-Regular', sans-serif;
  --inter-semiBold: 'Inter-SemiBold', sans-serif;
  --fira-medium: 'FiraSans-Medium', sans-serif;
  --fira-bold: 'FiraSans-Bold', sans-serif;
  --fira-light: 'FiraSans-Light', sans-serif;
  --fira-regular: 'FiraSans-Regular', sans-serif;
  --green: #91C03E;
  --text: #455154;
  --dark-green: #74AB14;
  --yellow: #EECA0F;
  --grey: #C4CACC;
  --grey900: #101828;
  --grey500: #667085; 
  --dark-grey: #969FA2;
  --light-grey: #F4F6F6;
  --grafit-grey: #979797;
  --blue: #225194;
  --light-blue:#4E74A9;
  --red: #DF2C2C;
  --bg: #F0F6FA;
  --light-bg:#F2F5FF;
  --orange: #F2A122;
  --rose: #F39E8433;
  --violet: #472EAB;
}

body {
  margin: 0;
  font-size: 16px;
  font-family: var(--sf-d-reg);
}

a {
  text-decoration: none;

}

a:hover {
  text-decoration: none;
}

.ant-checkbox-inner {
  border: 2px solid #d9d9d9;
  width: 18px;
  height: 18px;
}

.ant-popover-placement-rightTop > .ant-popover-content > .ant-popover-arrow {
  top: 2px;
  border: 1px solid var(--orange);
  border-top: 0;
  border-right: 0;
  background-color: #fff;
}


.ant-popover-placement-rightBottom > .ant-popover-content > .ant-popover-arrow {
  bottom: 1.3px;
  border: 1px solid var(--orange);
  border-top: 0;
  border-right: 0;
  background-color: #fff;
}
.ant-popover-placement-right > .ant-popover-content > .ant-popover-arrow {
  display: none;
}


.ant-popover-inner-content {
  /* border: 1px solid var(--orange); */
  max-width: 250px;
  max-height: 450px;
  overflow-y: auto;
  padding: 14px ;

}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: #E0EDC5;
  font-family: var(--medium);
}

.ant-message {
  z-index: 99999;
}


