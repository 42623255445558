.hide-choose-pay  .braintree-large-button {
  display: none !important;
}

.braintree-loaded [data-braintree-id='methods'], .braintree-loaded [data-braintree-id='options'] {
  margin-top: 10px;
}

.add-button .braintree-large-button {
  border: 1px solid #225194;
  border-radius: 4px;
  background-color: #fff;
  height: 40px;
  color: #225194;
  padding: 11px;
  text-decoration: none;
}

.add-button .braintree-large-button span {
  border-bottom: none;
}
